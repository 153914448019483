"use client";
import useStoreKeys from "@/hooks/useStoreKeys";
import { useProductContext } from "@/providers/ProductContextProvider";
import Link from "next/link";
import { FC } from "react";
import CompressedImage from "@/components/UI/CompressedImage/CompressedImage";
import styles from "./CommonProductDetails.module.scss";
import PlaceholderImage from "@/app/assets/images/img/placeholder.png";
import clsx from "clsx";
import useIntelliSuggestTracking from "@/hooks/useIntelliSuggestTracking";
import { ProductData } from "@/lib/5874/types";

interface Props {
	isCustomer: boolean;
	showSimpleView?: boolean;
	clampProductName?: boolean;
}

const CommonProductDetails: FC<Props> = ({
	isCustomer,
	showSimpleView = false,
	clampProductName,
}) => {
	const { store, lang } = useStoreKeys();
	const {
		product,
		product: { id, brand, media, name: productName, path: productLink, sku },
	} = useProductContext();

	// TODO: verify if gql media data is correct, then clean-up commented code below
	// const thumbnailImage = media.images.find((item) => item.isThumbnail === true);
	const thumbnailImage = product.media.images?.[0];

	const { findProductIntelData } = useProductContext();
	const { productClick } = useIntelliSuggestTracking();
	const intelData = findProductIntelData(product);

	const onClick = (product: ProductData) => {
		productClick(product.path, intelData?.data, intelData?.signature);
	};

	return (
		<div className={styles.commonDetails}>
			<CompressedImage
				src={thumbnailImage?.url.thumbnail ?? PlaceholderImage.src}
				altText={`Product ${productName} Image`}
				height={300}
				width={300}
				containerClass={clsx(styles.imageContainer, {
					[styles.imageContainerPadding]: clampProductName,
				})}
			/>
			<div className={styles.details}>
				{brand && (
					<Link
						href={brand?.path}
						onMouseDown={(e) => onClick(product)}
						className={styles.brandLink}
					>
						{brand?.name}
					</Link>
				)}
				{isCustomer && !showSimpleView && <p className={styles.sku}>{sku}</p>}
				<Link
					href={`/${store}/${lang}${productLink}`}
					onMouseDown={(e) => onClick(product)}
					className={clsx([
						styles.productName,
						clampProductName ? styles.ellipsis : "",
					])}
				>
					{productName}
				</Link>
			</div>
		</div>
	);
};

export default CommonProductDetails;
